@use './variables' as v;
@use "sass:map";

@mixin mq($type, $bp1, $bp2:0) {
    @if ($type == min) {
        @media screen and (min-width: #{map.get(v.$breakpoints, $bp1)}) {
        @content;
        }
    } @else if ($type == max) {
        $width: map.get(v.$breakpoints, $bp1);
        $width: $width - 1px;
        @media screen and (max-width: #{$width}) {
        @content;
        }
    } @else if ($type == bw) { // bw → between
        $width: map.get(v.$breakpoints, $bp2);
        $width: $width - 1px;
        @media screen and (min-width: #{map.get(v.$breakpoints, $bp1)})
        and (max-width: #{$width}) {
        @content;
        }
    }
}
