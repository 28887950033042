@charset "utf-8";
@use '../global/' as g;

#toTop {
  height: 50px;
  width: 50px;
  position: fixed;
  right: 30px;
  bottom: -50px;
  background: g.$color_blue;
  border: solid 2px g.$color_white;
  border-radius: 50%;
  color: g.$color_white;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: g.$zindex-toTop;
  transition-property: bottom transform box-shadow;
  transition-duration: .3s;
  transition-timing-function: ease-out;

  @media (hover: hover) and (pointer: fine) {

    &:hover {

      transform: scale(1.1);
      box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.7);
    }
  }

  &.js-visible {
    bottom: 20px;
  }
}