@use "./global" as g;
@use "sass:map";

:root {
  @include g.mq(max, sm) {
    font-size: .9em;
  }
}

html {
  font-family: g.$fontset_base;
  color: g.$color_black;
  background-color: g.$color_base;
}

body {
  width: 100%;
  height: 100%;
  // font-size: clamp(g.rem(16px), g.vw(18px, xl), g.rem(24px));
  font-feature-settings: "palt" 1;
  line-height: 1.6em;
  letter-spacing: .05em;
}

a {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: g.$fontset_heading;
  font-weight: 600;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

ol {
  margin-block: 0;
  margin-inline: 0;
  padding-block: 0;
  padding-inline: 0;
}

.container {
  max-width: map.get(g.$breakpoints, xxl);
  margin-right: auto;
  margin-left: auto;
  padding-right: g.vw(70px, xxl);
  padding-left: g.vw(70px, xxl);

  @include g.mq(max, md) {
    padding-right: g.vw(50px, md);
    padding-left: g.vw(50px, md);
  }
  @include g.mq(max, sm) {
    padding-right: g.vw(20px, md);
    padding-left: g.vw(20px, md);
  }
}

.svg-defs {
  display: none;
}

@include g.mq(min, sm) {
  br.pc {display: inline;}
  br.sp {display: none;}
}
@include g.mq(max, sm) {
  br.pc {display: none;}
  br.sp {display: inline;}
}