@charset "utf-8";
@use "../global" as g;

@import url(https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200);
// @import url(https://fonts.googleapis.com/icon?family=Material+Icons);

/* ===============================================
# 共通
=============================================== */
.arch {
  position: absolute;
  width: 100%;
  aspect-ratio: calc(1920 / 99) / 1;
  vertical-align: bottom;
  transform: translateY(-98%);

  .arch-white {
    fill: g.$color_base;
  }

  .arch-grey {
    fill: g.$color_lightgrey;
  }

  .arch-blue {
    fill: g.$color_sub;
  }

}

.sectionHeading {
  text-align: center;
  font-size: g.rem(36px);
  line-height: 1.4em;
  padding: 120px 0;

  @include g.mq(max, lg) {
    font-size: g.rem(26px);
    padding: 120px 0;
  }

  @include g.mq(max, sm) {
    font-size: g.rem(22px);
    padding: 60px 0;
  }

  >span {
    font-size: g.rem(21px);
    display: block;
    color: g.$color_yellow;

    @include g.mq(max, lg) {
      font-size: g.rem(17px);
    }
  }

  &::before {
    content: '●●●';
    font-size: g.rem(10px);
    display: block;
    color: g.$color_blue;
    letter-spacing: .5em;

    @include g.mq(max, lg) {
      font-size: g.rem(8px);
    }
  }
}

/* ===============================================
# ヘッダー
=============================================== */
header {
  --color_positive: #fff;
  --color_negative: #{g.$color_black};

  position: fixed;

  z-index: g.$zindex_header;
  width: 100%;

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    padding-top: g.vw(42px, xxl);

    transition-duration: .5s;
    transition-property: padding background-color;



    .headerLogo {
      width: clamp(300px, g.vw(400px, xxl), 500px);
      margin-right: auto;
      transition-property: width height;
      transition-duration: .5s;

      @include g.mq(max, sm) {
        width: 200px;
      }


      .logotype {
        fill: var(--color_positive);
      }

      .logomark-orange {
        fill: #f39800;
      }

      .logomark-red {
        fill: #eb613b;
      }

      &--black {
        .logotype {
          fill: #000;
        }
      }
    }

    .tel {
      font-size: min(g.vw(32px, xxl), 36px);
      margin-right: 1em;
      transition-property: font-size margin;
      transition-duration: .5s;

      @include g.mq(max, sm) {
        display: none;
      }
    }

    .tel-icon {
      color: var(--color_positive);
      border: 1px solid var(--color_positive);
      border-radius: .3em;
      line-height: .8em;
      padding: .1em;
      margin-right: 1.5em;
      display: none;

      @include g.mq(max, sm) {
        display: inline-block;
      }

      .material-symbols-outlined {
        font-variation-settings:
          'FILL' 1,
          'wght' 200,
          'GRAD' 0,
          'opsz' 40
      }
    }

    button.hamburgerBtn {

      width: clamp(14px, 12vw, 60px);
      aspect-ratio: 1 / 1;
      display: flex;
      flex: 0 0 auto;
      flex-wrap: wrap;
      align-items: center;
      border: none;
      background-color: unset;
      padding-inline: .7em;
      cursor: pointer;


      span {
        display: block;
        width: 100%;
        height: 1px;
        background-color: var(--color_negative);



        &::before,
        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: var(--color_negative);
          transition-property: transform;
          transition-duration: .3s;
        }

        &::before {
          transform: translateY(-.6em);
        }

        &::after {
          transform: translateY(.6em);
        }

        @include g.mq(max, sm) {

          background-color: var(--color_positive);

          &::before,
          &::after {
            background-color: var(--color_positive);
          }

        }

      }

      &:hover,
      &:active {
        span {
          &::before {
            transform: translateY(-.7em);
          }

          &::after {
            transform: translateY(.7em);
          }
        }
      }

      &.js-open {
        span {
          background-color: rgba(var(--color_positive), 0);

          &::before {
            transform: rotate(45deg);
            background-color: var(--color_positive);
          }

          &::after {
            transform: rotate(-45deg);
            background-color: var(--color_positive);
          }


        }
      }
    }

  }

  &.js-narrow {
    background-color: rgba($color: #fff, $alpha: .5);
    backdrop-filter: blur(12px);

    .container {
      padding: 0em 2em;

      .headerLogo {
        width: clamp(180px, g.vw(400px, xxl), 400px);
        height: auto;

        .logotype {
          fill: var(--color_negative);
        }
      }

      .tel {
        font-size: clamp(1em, g.vw(30px, xxl), 2em);
      }

      .tel-icon {
        @include g.mq(max, sm) {
          display: inline-block;
          color: var(--color_negative);
          border: 1px solid var(--color_negative);
        }
      }

      .hamburgerBtn {
        span {
          @include g.mq(max, sm) {

            background-color: var(--color_negative);

            &::before,
            &::after {
              background-color: var(--color_negative);
            }
          }
        }

        &.js-open {
          --color_positive: #{g.$color_black};
        }
      }

    }

  }
}

/* ===============================================
# モーダル
=============================================== */
#modal {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: g.$zindex_modal_menu;
  background-color: rgba(g.$color_black, $alpha: .95);

  visibility: hidden;
  opacity: 0;
  transition-property: visibility opacity;
  transition-duration: .5s;

  dl {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    height: 100%;
    right: 0;
    padding: 5em 2em 5em 4em;
    line-height: 3em;
    letter-spacing: .06em;
    font-size: clamp(1rem, g.vw(23px, xl), 23px);
    background-color: g.$color_blue;
    border-radius: 0 0 0 5em;
    transform: translateX(10%);
    transition-property: transform;
    transition-duration: .5s;

    dt {
      color: lighten(g.$color_blue, 20);
      display: flex;
      align-items: center;

      &::after {
        content: '';
        width: 80%;
        margin-left: 1em;
        background-color: lighten(g.$color_blue, 20);
        ;
        display: inline-block;
        height: 1px;
      }
    }

    a {
      color: g.$color_white;

      &:hover {
        opacity: .8;
      }
    }


  }

  &.js-active {
    visibility: visible;
    opacity: 1;

    dl {
      transform: translateX(0);
    }
  }


}

/* ===============================================
# メインビジュアル
=============================================== */
.mainVisual {
  background-color: #EAEAEA;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 1;
    width: 125%;
    aspect-ratio: 1/1;
    background-color: g.$color_blue;
    border-radius: 50%;
    transform: translate(-50%, -50%);

    @include g.mq(max, md) {
      width: 210%;
    }
  }

  &::after {
    content: '';
    background: url('../img/MV_photo.png') no-repeat bottom right/contain;
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(70%);
    z-index: 2;
    width: min(62%, 954px);
    aspect-ratio: 1 / 1;

    @include g.mq(max, md) {
      width: 90%;
      right: 50%;
      transform: translateX(50%);
      z-index: 20;
    }
  }

  .container {
    position: relative;
    z-index: g.$zindex_mainVisual;
    padding-top: g.vw(220px, xxl);
    padding-bottom: g.vw(330px, xxl);

    @include g.mq(max, md) {
      padding-top: g.vw(320px, xxl);
      padding-bottom: g.vw(830px, xxl);
    }

  }

  h1 {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    font-size: clamp(1rem, g.vw(46px, xxl), 46px);
    // margin-top: g.vw(220px, xxl);
    color: #fff;

    @include g.mq(max, sm) {
      font-size: 1.35rem;
    }


    .row01 {

      font-size: 1.1em;
      margin-left: 3.5em;


      span {
        display: inline-block;
        border: 1px solid g.$color_white;
        border-radius: .1em;
        padding: .15em;
        margin: .07em;
        line-height: 1em;
      }
    }

    .row02 {
      span {
        display: inline-block;
        background-color: g.$color_yellow;
        border: 1px solid g.$color_yellow;
        border-radius: .1em;
        font-size: 1.54em;
        padding: .1em;
        margin: .07em;
        line-height: 1em;
      }
    }

    .row03 {
      margin-top: .2em;
    }

    .row03,
    .row04 {
      line-height: 1.5em;

      >em {
        font-size: 1.3em;
        font-style: normal;
        background: linear-gradient(transparent 60%,
            g.$color_yellow 60%,
            g.$color_yellow 85%,
            transparent 85%,
          );

      }
    }
  }

  ul.features {
    display: flex;
    margin-top: g.vw(46px, xxl);
    color: #fff;
    font-size: clamp(.5rem, g.vw(20px, xxl), 22px);

    @include g.mq(max, sm) {
      font-size: .6em;
    }

    li {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-width: 9em;
      border: 1px solid g.$color_yellow;
      border-radius: 50%;
      line-height: 1.8em;
      font-weight: bold;
      margin-right: .8em;
      padding: 1em;
      aspect-ratio: 1 / 1;

      span {
        display: inline-block;
        color: g.$color_yellow;
        margin-top: -1.5em;
        letter-spacing: .2em;
        font-size: .8em;


        &::before,
        &::after {
          content: '★';

        }
      }

      >em {
        display: inline-block;
        font-size: 1.5em;
        font-style: normal;


      }
    }
  }
}

/* ===============================================
# 紹介動画
=============================================== */
.introductionMovie {

  background-color: g.$color_grey;



  .wrapper {
    display: grid;
    grid-template-columns: 2.5fr 7.5fr;
    grid-template-areas:
      "movie  movie"
      "icon   text";
    gap: .8em;
    padding: g.vw(51px, xxl) g.vw(110px, xxl);
    background-color: #fff;
    border-radius: g.vw(70px, xxl);
    margin-top: g.vw(-51px, xxl);
    position: relative;
    z-index: g.$zindex_youtube;
    box-shadow: 0px 6px 16px 0px rgba($color: #000000, $alpha: .2);

    @include g.mq(max, sm) {
      grid-template-columns: auto;
      grid-template-areas:
        "icon"
        "movie"
        "text";
      padding: 6% 3%;
    }

    h2 {
      aspect-ratio: 208 / 146;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      min-width: 5em;
      margin-right: .5em;
      font-size: min(g.vw(14px), 32px);
      color: #fff;
      background-image: url("../img/playmark.png");
      background-repeat: no-repeat;
      background-size: cover;

      @include g.mq(max, lg) {
        margin-right: .5em;
      }

      @include g.mq(max, sm) {
        flex-direction: row;
        font-size: g.vw(24px);

        aspect-ratio: unset;
        background-image: unset;
        color: g.$color_black;

        &::before {
          content: '';
          height: 1em;
          aspect-ratio: 208 / 146;
          margin-right: .5em;
          background-image: url("../img/playmark.png");
          background-size: contain;
          background-repeat: no-repeat;
        }
      }

      >span {
        display: block;
        text-align: center;
        line-height: 1.5em;
        font-size: .4em;

        @include g.mq(max, sm) {
          font-size: .6em;
          margin-left: 1em;
        }
      }
    }

    hgroup {
      display: flex;
      flex-direction: column;
      justify-content: center;

      // font-size: min(g.vw(20px), 30px);
      font-size: g.vw(13px);

      >h3 {
        display: flex;
        flex-wrap: nowrap;
        align-items: end;
        white-space: nowrap;

        @include g.mq(max, sm) {
          font-size: g.vw(20px);
        }

        small {
          display: flex;
          flex-wrap: nowrap;
          font-size: 1.3em;

          @include g.mq(max, sm) {
            font-size: g.vw(26px);
          }

          span {
            display: inline-block;
            border: 1px solid g.$color_black;
            border-radius: .1em;
            padding: .2em;
            margin: .05em;
            line-height: 1em;
          }
        }

        >big {
          display: flex;
          flex-wrap: nowrap;
          font-size: 1.3em;

          @include g.mq(max, sm) {
            font-size: g.vw(26px);
          }

          margin-right: .2em;

          span {
            display: inline-block;
            background-color: g.$color_yellow;
            border: 1px solid g.$color_yellow;
            color: #fff;
            border-radius: .1em;
            padding: .2em;
            margin: .05em;
            line-height: 1em;
          }
        }
      }
    }

    p {
      margin-top: .5em;
      font-size: .7em;

      @include g.mq(max, sm) {
        font-size: g.rem(16px);
      }
    }

    .youtube {
      position: relative;
      grid-area: movie;
      padding-top: 56.25%;

    }

    .youtube iframe {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: .5em;
    }
  }
}

/* ===============================================
# 企業型DCのメリット
=============================================== */
.meritOfDC {

  .container {
    padding-bottom: 250px;

    @include g.mq(max, lg) {
      padding-bottom: 100px;
      padding-left: 2em;
      padding-right: 2em;
    }
  }


  ol {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
    gap: 6em 2em;

    @include g.mq(max, lg) {
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    }

    @include g.mq(max, sm) {
      grid-template-columns: auto;
    }

    >li {
      position: relative;
      display: flex;
      box-shadow: 0px 6px 16px 0px rgba($color: #000000, $alpha: .2);


      >span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        z-index: 1;
        width: 8em;
        aspect-ratio: 1/ 1;
        background-color: g.$color_yellow;
        border-radius: 50%;
        color: g.$color_white;
        text-align: center;
        font-weight: 400;
        font-size: g.rem(13px);
        transform: translate(30%, -50%);

        @include g.mq(max, sm) {
          transform: translate(-50%, -50%);
          left: 50%;
          width: 6em;
        }

        >em {
          line-height: 1em;
          display: block;
          font-weight: 400;
          font-size: g.rem(50px);

          @include g.mq(max, sm) {
            font-size: g.rem(30px);
          }
        }
      }




      >dl {

        list-style: none;
        display: grid;
        grid-auto-rows: 1fr 1fr;
        overflow: hidden;




        >dt {
          margin: g.rem(34px);
          font-size: g.rem(26px);
          font-weight: bold;
          text-align: center;
          color: g.$color_white;
          position: relative;

          border-top-left-radius: 0;
          border-top-right-radius: 0;
          border-bottom-right-radius: 1000px 300px;
          border-bottom-left-radius: 1000px 300px;
          background-color: g.$color_blue;
          margin: 0 -100px;
          padding: 35px;

          @include g.mq(max, sm) {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: .5em;
          }


          &::before {
            display: block;
            transform: scale(80%);

            @include g.mq(max, sm) {
              transform: scale(70%);
            }
          }

          &.merit_icon_01::before {
            content: url(../img/merit_icon_01.png);
          }

          &.merit_icon_02::before {
            content: url(../img/merit_icon_02.png);
          }

          &.merit_icon_03::before {
            content: url(../img/merit_icon_03.png);
          }

        }

        >dd {
          margin: 2em;
          position: relative;
          text-align: justify;
          line-height: 1.9em;
          font-size: g.rem(18px);

        }

      }
    }
  }
}

/* ===============================================
# あいわOfficeに依頼するメリット
=============================================== */
.meritOfRequesting {
  background-color: g.$color_lightgrey;

  .container {
    padding-bottom: 200px;

    @include g.mq(max, lg) {
      padding-bottom: 30px;
    }
  }

  ol {
    >li {
      display: flex;
      margin-bottom: 3em;
      align-items: center;

      @include g.mq(max, lg) {
        gap: 3em;
        margin-bottom: 7em;
      }

      @include g.mq(max, sm) {
        gap: 1em;
        flex-direction: column-reverse;
      }

      .wrapper {
        width: 50%;
        padding: 2em;

        @include g.mq(max, lg) {
          width: auto;
          padding: 0;

          &:has(>img) {
            width: 35%;
            flex: 0 0 auto;

            @include g.mq(max, sm) {
              width: 80%
            }
          }
        }

        @include g.mq(max, sm) {
          padding: 1em;
        }

        >img {
          border-radius: 50%;
          box-shadow: -30px 20px 0px 0px g.$color_blue;

          @include g.mq(max, lg) {
            box-shadow: -10px 10px 0px 0px g.$color_blue;
          }

          @include g.mq(max, sm) {
            box-shadow: -10px 10px 0px 0px g.$color_blue;
          }
        }

        >span {
          display: block;
          color: g.$color_yellow;
          font-size: g.rem(20px);
          font-weight: 600;
          position: relative;

          >em {
            font-size: g.rem(200px);
            font-weight: 400;
            position: absolute;
            right: 0;
            top: 0;
            line-height: .8em;
            letter-spacing: -.03em;

            @include g.mq(max, lg) {
              font-size: g.rem(140px);
            }
          }

          &::after {
            content: '';
            display: block;
            height: 1px;
            width: 3em;
            background-color: g.$color_black;
            position: absolute;
            top: 50%;
            left: 3.5em;
          }
        }

        >dl {
          position: relative;
          z-index: 1;

          >dt {
            font-size: g.rem(32px);
            font-weight: 600;
            margin-top: .5em;

            @include g.mq(max, lg) {
              font-size: g.rem(26px);
            }

            >small {
              font-size: g.rem(16px);
              font-weight: 400;
              display: block;
            }
          }

          >dd {
            margin-top: 3em;
            line-height: 2em;
            text-align: justify;
          }
        }
      }

      &.reverse {
        flex-direction: row-reverse;

        @include g.mq(max, sm) {
          flex-direction: column-reverse;
        }

        .wrapper {
          >img {
            box-shadow: 30px 20px 0px 0px g.$color_blue;

            @include g.mq(max, lg) {
              box-shadow: 10px 10px 0px 0px g.$color_blue;
            }

            @include g.mq(max, sm) {
              box-shadow: -10px 10px 0px 0px g.$color_blue;
            }
          }
        }
      }

    }
  }
}

/* ===============================================
# 導入企業の声
=============================================== */
.usersVoice {

  padding-bottom: 200px;

  @include g.mq(max, lg) {
    padding-bottom: 80px;
  }

  ul.voiceList {

    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10em;

    @include g.mq(max, lg) {
      gap: 8em;
    }

    @include g.mq(max, sm) {
      gap: 5em;
    }


    >li {

      display: grid;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      grid-template-areas:
        "info title"
        "info text";

      @include g.mq(max, lg) {
        grid-template-columns: auto;
        grid-template-rows: auto;
        grid-template-areas:
          "title"
          "info"
          "text";
      }

      &.grid-reverse {
        grid-template-areas:
          "title  info"
          "text   info";

        @include g.mq(max, lg) {
          grid-template-columns: auto;
          grid-template-rows: auto;
          grid-template-areas:
            "title"
            "info"
            "text";
        }

        .wrapper-info {
          .back {
            justify-content: start !important;
            border-radius: 150px 0 0 150px !important;
            margin-left: 0 !important;
            margin-right: calc(50% - 50vw) !important;
          }

          .text {
            right: 0;
            text-align: right;

            @include g.mq(max, lg) {
              margin-right: 1rem;
            }
          }

          .photo {
            left: 15%;
          }

          ul.info {
            text-align: right;

            @include g.mq(max, lg) {
              margin-right: 1rem;
            }
          }
        }

        .wrapper-title {
          padding-left: 0;
          padding-right: 4em;

          @include g.mq(max, lg) {
            padding-right: 1em;
            padding-left: 1em;
          }
        }

        .wrapper-text {
          padding-left: 0;
          padding-right: 4em;

          @include g.mq(max, lg) {
            padding: 3em 2em 0;
          }
        }
      }






      .wrapper-info {
        grid-area: info;
        position: relative;
        top: 50px;

        ul.images {

          .back {
            display: flex;
            bottom: 0;
            height: 300px;
            overflow: hidden;
            justify-content: end;
            border-radius: 0 150px 150px 0;
            margin-left: calc(50% - 50vw);


            @include g.mq(max, lg) {
              height: 200px;
            }

            img {
              mix-blend-mode: multiply;
              aspect-ratio: 826 / 411;
            }
          }

          .text {
            position: absolute;
            top: 10%;
            color: rgba(g.$color_white, .15);
            font-style: italic;
            font-size: g.rem(50px);
            line-height: 1em;

            @include g.mq(max, lg) {
              margin-left: 1rem;
            }
          }

          .photo {
            position: absolute;
            right: 15%;
            bottom: 100%;
            height: 350px;
            margin-bottom: -300px;


            @include g.mq(max, lg) {
              height: 230px;
              margin-bottom: -200px;
            }

            img {
              aspect-ratio: 1 / 1;
              height: 100%;
            }

          }
        }

        ul.info {
          margin-top: -5em;
          position: relative;
          z-index: 1;

          @include g.mq(max, lg) {
            margin-left: 1rem;
          }

          >.company {
            font-size: g.rem(24px);
            font-weight: 600;
            color: g.$color_white;
          }

          >.name {
            display: inline;
            font-size: g.rem(24px);
            font-weight: 600;
            color: g.$color_white;

            span {
              font-size: .6em;
              vertical-align: baseline;

              &:first-child {
                margin-right: 1em;
              }
            }
          }

          >.tags {
            font-size: .8em;
            margin-top: 2em;

            span {
              background-color: g.$color_lightgrey;
              border-radius: 3em;
              margin-right: .5em;
              padding: .2em 1.2em;
            }
          }

          >.link {
            font-size: .8em;
            margin-top: 1em;

            a {
              text-decoration: underline;
              color: g.$color_link;
              line-height: 1.6em;
              padding-right: 2em;

              &:hover {
                opacity: .7;
              }

              &::after {
                font-family: "Material Symbols Outlined";
                content: '\e89e';
                margin-left: .2em;
                text-decoration: none;
                position: absolute;
                font-size: 1.4em;
              }
            }
          }
        }
      }

      .wrapper-title {
        grid-area: title;
        padding-left: 4em;

        @include g.mq(max, lg) {
          padding-right: 1em;
          padding-left: 1em;
        }

        >span {
          display: block;
          font-size: g.rem(20px);
          font-weight: 600;
          position: relative;

          >em {
            font-size: g.rem(180px);
            font-weight: 400;
            position: absolute;
            z-index: 1;
            right: 0;
            top: 0;
            line-height: .8em;
            letter-spacing: -.03em;

            @include g.mq(max, lg) {
              font-size: g.rem(150px);
            }

          }

          &::after {
            content: '';
            display: block;
            height: 1px;
            width: 3em;
            background-color: g.$color_black;
            position: absolute;
            top: 50%;
            left: 3.5em;

            @include g.mq(max, lg) {
              width: 60%;
            }

            @include g.mq(max, sm) {
              width: 5em;
            }
          }
        }

        >h3 {
          font-size: g.rem(32px);
          font-weight: 600;
          margin-top: .5em;
          position: relative;
          z-index: 1;

          @include g.mq(max, lg) {
            font-size: g.rem(30px);
          }

          >small {
            font-size: g.rem(16px);
            font-weight: 400;
            display: block;
          }
        }


      }

      .wrapper-text {
        grid-area: text;
        padding-left: 4em;

        @include g.mq(max, lg) {
          padding: 3em 2em 0;
        }

        >p {
          margin-top: 3em;
          line-height: 2.2em;
          text-align: justify;

          em {
            font-style: normal;
            font-weight: bold;
            color: g.$color_yellow;
          }
        }
      }




      // voice01 のスタイル
      &.voice_01 {
        $color: #00bcbc;

        .back {
          background-color: $color;
        }

        .title {
          >span {
            color: $color;
          }
        }
      }

      // voice02 のスタイル
      &.voice_02 {
        $color: #ceacfb;
        $color02: #8FC5FC;

        .back {
          background: linear-gradient(90deg, $color02, $color);
        }

        .title {
          >span {
            color: $color;
          }
        }
      }

      // voice03 のスタイル
      &.voice_03 {
        $color: #2F8AC9;

        .back {
          background-color: $color;
        }

        .photo {
          right: 0 !important;
          margin-left: calc(50% - 50vw);

          img {
            aspect-ratio: 160 / 81 !important;
          }
        }

        .title {
          >span {
            color: $color;
          }
        }
      }


    }
  }
}

/* ===============================================
# 導入の流れ
=============================================== */
.Introduction {

  background-color: g.$color_lightgrey;
  padding-bottom: 200px;

  @include g.mq(max, lg) {
    padding-bottom: 160px;
  }

  @include g.mq(max, sm) {
    padding-bottom: 80px;
  }

  ol.flowList {
    display: flex;
    flex-direction: column;
    gap: 1em;
    list-style: none;
    margin-inline: 5em;

    @include g.mq(max, lg) {
      margin-inline: 0;
    }

    >li {
      border: 1px solid g.$color_grey;
      border-radius: 10em;
      padding: .6em 5em .6em .6em;
      display: flex;
      align-items: center;
      gap: 4em;
      position: relative;

      @include g.mq(max, lg) {
        gap: .6em;
        padding: .6em 1em .6em .4em;
      }

      >span {
        display: flex;
        flex: 0 0 8em;
        flex-direction: column;
        align-items: center;
        background-color: #fff;
        border-radius: 10em;
        aspect-ratio: 1 / 1;
        font-size: g.rem(12px);
        font-style: italic;
        line-height: 4em;
        color: g.$color_yellow;

        @include g.mq(max, lg) {
          font-size: g.rem(7px);
        }

        >em {
          font-size: 3em;
          line-height: .4em;
          color: g.$color_grey;


        }
      }

      >dl {
        >dt {
          font-weight: bold;
          font-size: 1.2em;

        }

        >dd {
          margin-top: .5em;

          @include g.mq(max, lg) {
            margin-top: 0;
          }
        }
      }

      &:not(:last-child)::after {
        content: '';
        position: absolute;
        top: 100%;
        left: 6.5em;
        width: 0;
        height: 0;
        border-style: solid;
        border-right: .5em solid transparent;
        border-left: .5em solid transparent;
        border-top: 1em solid #555555;
        border-bottom: 0;
      }
    }
  }


}

/* ===============================================
# 話だけでも
=============================================== */
.forNow {
  background-color: g.$color_sub;
  color: g.$color_white;

  .wrapper {
    background: url(../img/fornow_photo.png) no-repeat right bottom / contain;
    display: flex;
    gap: 2em;
    flex-direction: column;
    align-items: center;
    padding-block: 3em;
    padding-right: 300px;
    text-align: center;
    font-size: g.rem(20px);
    font-weight: bold;


    @include g.mq(max, lg) {

      text-align: center;
      padding: 3em 0 50vw;
      background-size: 50%;
      background-position-x: center;
    }

    strong {
      display: block;
      font-size: 2.7em;
      margin-top: .7em;


      @include g.mq(max, lg) {
        line-height: 1em;
        margin-bottom: .5em;
      }
    }

    p.side-line {
      position: relative;

      @include g.mq(max, lg) {
        text-align: center;
        font-size: .9em;
      }


      &::before,
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        background-color: #fff;
        width: 1px;
        height: 3em;

        // @include g.mq(max, sm) {
        //   // height: 6em;
        //   display: none;
        // }
      }

      &::before {
        left: -1em;
        transform: rotate(-20deg);

      }

      &::after {
        right: -1em;
        transform: rotate(20deg);

      }
    }
  }

}

/* ===============================================
# フッター
=============================================== */
footer {
  color: g.$color_white;
  background-color: g.$color_main;

  .container {
    display: grid;
    grid-template-areas:
      "head tel form"
      "info tel form";
    gap: 3em;
    overflow: hidden;

    @include g.mq(max, lg) {
      grid-template-areas:
        "head"
        "info"
        "tel"
        "form";
      place-items: center;
      gap: 2em;
    }

    h2 {
      grid-area: head;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: .5em;

      text-align: center;
      font-size: g.rem(20px);
      letter-spacing: .3em;
      margin-top: -2.2em;
      height: 11em;
      aspect-ratio: 1 /1;
      border: 1px solid rgba(g.$color_white, .5);
      border-radius: 30em;

      @include g.mq(max, lg) {
        border: none;
        margin-top: 0;
      }


      >span {
        font-size: g.rem(16px);
        display: block;
        color: g.$color_yellow;
        font-weight: 300;
        font-style: italic;
        letter-spacing: .05em;
      }

      &::before {
        content: '●●●';
        font-size: g.rem(6px);
        display: block;
        color: g.$color_white;
      }
    }

    .information {
      grid-area: info;
      display: flex;
      flex-direction: column;
      gap: 2em;
      font-size: .9em;
    }

    .tel {
      grid-area: tel;
      display: flex;
      justify-content: center;
      margin-block: 3em;

      @include g.mq(max, lg) {
        margin-block: 0em;
      }


      a {
        border-radius: 50%;
        border: 1px solid g.$color_yellow;
        color: g.$color_white;
        aspect-ratio: 1 / 1;
        width: 18em;
        transition: transform .3s;

        @include g.mq(max, lg) {
          aspect-ratio: unset;
          width: auto;
          border-radius: 10em;
        }

        dl {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: .5em;
          border-radius: 50%;
          background-color: g.$color_yellow;
          height: 17em;
          margin: .5em;
          transition-property: height margin;
          transition-duration: .3s;

          @include g.mq(max, lg) {
            border-radius: 10em;
            height: auto;
            padding: 2em 3em;
          }

          dt {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: -3em;
            margin-bottom: .8em;

            @include g.mq(max, lg) {
              flex-direction: row;
              margin-top: 0;
            }

            &::before {
              font-family: "Material Symbols Outlined";
              content: '\e61d';
              font-variation-settings: "wght" 200;
              font-size: 4em;
              line-height: 1em;

              @include g.mq(max, lg) {
                font-size: 2.5em;
                margin-right: .2em;
              }
            }
          }

          dd:nth-child(2) {
            font-size: 2em;
            font-weight: 300;
            border-bottom: 1px solid g.$color_white;
            line-height: .9em;
          }

          dd:nth-child(3) {
            font-size: .8em;
          }
        }

        @include g.mq(min, sm) {

          &:hover,
          &:active {
            transform: scale(1.01);

            dl {
              height: 17.6em;
              margin: .2em;
            }
          }
        }
      }


    }

    .form {
      grid-area: form;
      display: flex;
      justify-content: center;
      margin-block: 3em;

      @include g.mq(max, lg) {
        margin-block: 0em;
      }


      a {
        border-radius: 50%;
        border: 1px solid g.$color_yellow;
        color: g.$color_white;
        aspect-ratio: 1 / 1;
        width: 18em;
        transition: transform .3s;

        @include g.mq(max, lg) {
          aspect-ratio: unset;
          width: auto;
          border-radius: 10em;
        }

        dl {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: .5em;
          border-radius: 50%;
          background-color: g.$color_yellow;
          height: 17em;
          margin: .5em;
          transition-property: height margin;
          transition-duration: .3s;

          @include g.mq(max, lg) {
            border-radius: 10em;
            height: auto;
            padding: 2em 3em;
          }


          dt {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: -3em;
            margin-bottom: .8em;

            @include g.mq(max, lg) {
              flex-direction: row;
              margin-top: 0;
            }


            &::before {
              font-family: "Material Symbols Outlined";
              content: '\e158';
              font-variation-settings: "wght" 200;
              font-size: 4em;
              line-height: 1em;

              @include g.mq(max, lg) {
                font-size: 2.5em;
                margin-right: .2em;
              }
            }
          }

          dd:nth-child(2) {
            border: 1px solid g.$color_white;
            border-radius: 3em;
            padding: 1em 2em;
            font-size: .9em;
            line-height: .9em;
          }
        }

        &:hover,
        &:active {
          transform: scale(1.01);

          dl {
            height: 17.6em;
            margin: .2em;
          }
        }
      }
    }
  }


  .footerLogo {
    width: 300px;



    .logotype {
      fill: #fff;
    }

    .logomark-orange {
      fill: #f39800;
    }

    .logomark-red {
      fill: #eb613b;
    }
  }

  .copylight {
    text-align: center;
    font-size: g.rem(12px);
    padding: 2rem 0 .5rem;
    letter-spacing: .1em;
  }
}