// ブレイクポイント
$breakpoints: (
  'xs': 0,
  'sm': 576px,
  'md': 768px,
  'lg': 992px,
  'xl': 1200px,
  'xxl': 1500px
) !default;

// カラーコード
$color_base: #ffffff; // バックグラウンドカラー
$color_base_sub: #EAEAEA; // バックグラウンドカラー
$color_main: #285DA6;
$color_sub: #5F7CBC;
$color_white: #fefcf0;
$color_black: #272727;
$color_grey: #5a5a5a;
$color_lightgrey: #EAEAEA;
$color_link: #285DA6;

$color_yellow: #EBA82E;
$color_blue: #285DA6;

// z-index
$zindex_loader: 2000;
$zindex_header: 100;
$zindex_modal_menu: 50;
$zindex_toTop: 30; 
$zindex_youtube: 20; 
$zindex_mainVisual: 10; 

// フォントセット
$fontset_base: 'Poppins', 'Noto Sans JP', sans-serif;
$fontset_heading: 'Poppins', 'Noto Sans JP', sans-serif;
